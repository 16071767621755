import React from "react"
import { graphql } from "gatsby"
import Button from "../components/Button"
import ComingUp from "../components/ComingUp"
import Container from "../components/Container"
import Duplet from "../components/Duplet"
import Img from "../components/Img"
import MediaFigure from "../components/MediaFigure"
import Scroller from "../components/Scroller"
import Section from "../components/Section"
import Site from "../components/Site"
import SiteLink from "../components/SiteLink"
import Svg from "../components/Svg"
import { formatLink } from '../helpers'
import "../css/ministry.scss"

const Ministry = ({data}) => (
  <Site
    title     = {`${data.datoCmsMinistry.reference} Ministry`}
    className = {`hcc-ministry hcc-ministry-${data.datoCmsMinistry.slug}`}
  >
    <article style={{'--c-ministry' : `var(--c-${data.datoCmsMinistry.slug})`, '--media-figure-background-color' : `var(--c-${data.datoCmsMinistry.slug})`}} data-datocms-noindex>
      <MinistryIntro data={data}/>
      <MinistryBranches data={data}/>
    </article>
  </Site>
)

export default Ministry


// Intro
// -----

const MinistryIntro = ({data}) => (
  <Section tag="header" className="hcc-ministry-intro hcc-grid">
    <Duplet
      h         = "1"
      className = "hcc-ministry-title"
      title     = {<><MediaFigure type={data.datoCmsMinistry.slug}/><span>{data.datoCmsMinistry.title}</span></>}
      text      = {data.datoCmsMinistry.ministryIntro}
      // text      = {<Doom content={data.datoCmsMinistry.ministryIntro}/>}
      center    = {['xs', 'sm']}
      style     = {{
        "--btn-background-color"     : `var(--c-${data.datoCmsMinistry.slug})`,
        "--btn-background-color-int" : `var(--c-${data.datoCmsMinistry.slug})`,
      }}
    >
      {data.datoCmsMinistry.slug === "missions" &&
        <Button label="Give to Missions" href="https://ppay.co/Ewt3KSipGxo" target="_blank"/>
      }
    </Duplet>
    <aside className="hcc-ministry-upcoming">
      <ComingUp
        showTitle = {true}
        count     = {3}
        ministry  = {data.datoCmsMinistry.slug}
        fallback  = {
          <div className="hcc-ministry-upcoming-empty">
            <Duplet
              title  = {`Aw, shucks...`}
              text   = {<>There aren't any events coming up for the {data.datoCmsMinistry.reference} Ministry at the moment, but you can keep up with everything else going on at Hillside on the&nbsp;<SiteLink href={formatLink('', 'e')}>Events</SiteLink>&nbsp;page.</>}
              center = {['xs']}
            />
            <figure>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 505.6 732.3" xmlSpace="preserve">
                <style type="text/css">
                  {`
                    .st1,
                    .st2 {
                      fill: none;
                      stroke-width: 3;
                      stroke-miterlimit: 10;
                    }
                    .st1 { stroke: #000000; }
                    .st2 { stroke: rgba(255, 255, 255, 0.33); }
                    .st3 { fill: #000000; }
                    .st4 { fill: rgba(255, 255, 255, 0.33); }
                    .st5 { fill: #ffffff; }
                    .st6 { fill: #ffffff; }
                  `}
                </style>
                <path class="st1" d="M306.8,117.5c0,0,39.6,25.6,88.3,8.9c43.3-14.9,48-18.5,48-18.5l-15.7,48.9c0,0-57.8,41.2-120.6,9.2"/>
                <path class="st3" d="M223,355.3l115.7,94.8l2,185.9l145.4-51.7c0,0-19.8-16.7-62-4.9c0,0,28-154.6,13.2-184.1S271.2,207.7,202.2,236c-72.7,29.9-66.5,248.1-66.5,248.1L0,592.5l17.8,81.8l57.8,58c0,0-3.7-41-18.5-69.8c0,0,151.4-65.8,173.5-126.1s46.5-231.6,46.5-231.6"/>
                <path class="st4" d="M186.3,147.8l44,63.8c8.3,12,24.6,15,36.6,6.7c4.1-2.8,7.4-6.8,9.3-11.4l26.8-63.8c5.6-13.4-0.6-28.8-14-34.5c-3.2-1.4-6.7-2.1-10.2-2.1H208C186.8,106.5,174.3,130.3,186.3,147.8z"/>
                <polygon class="st5" points="309.6,202.2 355.1,155.3 416.2,142 432.1,72 505.6,228.2 488.6,296 423.9,304.1 372.2,347.8   "/>
                <polygon class="st3" points="488.6,296 423.9,304.1 355.1,155.3 416.2,142  "/>
                <path class="st6" d="M218.1,112.1l22.5-51.7c0,0-15.5-18.9-5.7-31.7s21.7,0,21.7,0s15.5-16.6,37.4-8.9s22.8,37.1,13.9,53.3C294.8,97,277.5,103.3,255,86.9l-13.4,28.6"/>
                <path class="st3" d="M247,23.3c0,0,3.3-25.2,35.8-23.2s34.5,14.3,47.7,11.5s14.8,36.6-20.6,32.6s-33.7-5.8-33.7-5.8s-4.3,15.4-12.9,12.1s-7.5-20-7.5-20"/>
                <path class="st1" d="M447.2,108c0,0-3.4,52,1.5,53.2s14.8-35.7,14.8-35.7s-8.6,43.7-3.7,44.9s12.9-31.2,12.9-31.2s-5.2,35.2,0,35.5s10.3-37.1,2-47.6c-5.5-6.8-11.7-13-18.5-18.5"/>
                <path class="st1" d="M177.5,148.4c0,0,12.1,67.5,37,89.7s101.1,37.1,114.9,32.5s28-16.6,26.8-20s-30.6,7.3-30.6,7.3s35.5-17.2,33.4-22.9c-2.7-7.4-37.4,9.8-37.4,9.8s29.5-17.3,27.9-22c-2.1-6-31.6,5.9-31.6,5.9L256.7,206l-10-33.6"/>
                <line class="st1" x1="299.2" y1="67.6" x2="298.4" y2="81.4"/>
                <circle class="st3" cx="291.4" cy="61.8" r="3.7"/>
                <circle class="st3" cx="306.8" cy="66.6" r="3.8"/>
                <line class="st2" x1="274" y1="336.3" x2="261.6" y2="422.9"/>
                <path class="st2" d="M210.1,233.6c0,0,17.3,24.3,95.9,36.6"/>
              </svg>
            </figure>
          </div>
        }
      />
      {/*    
      <div>
        <h2>Get plugged in.</h2>
        <p>The best way to connect with serving opportunities and smaller groups of believers at Hillside is to attend <strong>Go:Discover.</strong></p>
        <Button href={formatLink('discover', 'go')} label="Register Today" suffix={<Svg type="rarr"/>}/>
      </div>
      */}
    </aside>
    <figure className="hcc-ministry-poster" style={{backgroundImage : `url(${data.datoCmsMinistry.image.url}`}}/>
    <i className="hcc-ministry-gradient" aria-hidden="true"></i>
  </Section>
)


// Branches
// --------

const MinistryBranches = ({data}) => (
  <>
    {data.allDatoCmsMinistryBranch.edges.length > 0 &&
      <Section className="hcc-ministry-branches">
        <Container>
          <h2>Get Involved</h2>
        </Container>
        <Scroller showButtons={true}>
          <ul className="hcc-flex">
            {data.allDatoCmsMinistryBranch.edges.map(({node : branch}) => {
              console.log(branch)
              return (
                <li key={`${data.datoCmsMinistry.slug}-${branch.name}`}>
                  <SiteLink
                    href      =  {formatLink(`${data.datoCmsMinistry.slug}/${branch.slug}`, 'mb')}
                    className = "is-branch"
                  >
                    <Img
                      src    = {branch.image.url}
                      alt    = {branch.name}
                      width  = {branch.image.width / 2}
                      height = {branch.image.height / 2}
                      hash   = {branch.image.blurhash}
                    />
                    <Duplet
                      h     = "3"
                      title = {<span>{branch.name}</span>}
                      text  = {<span>{branch.excerpt}</span>}
                    />
                  </SiteLink>
                </li>
              )
            })}
            <li>
              <SiteLink
                href      = {formatLink('discover', 'go')}
                className = "is-go"
              >
                <Duplet
                  h     = "4"
                  title = {<span>Wait,<br/>there&apos;s<br/>more&hellip;</span>}
                  text  = {<span>The best way to connect with serving opportunities and smaller groups of believers at Hillside is to attend <strong>Go:Discover.</strong></span>}
                />
                <Button label="Register Today" suffix={<Svg type="rarr"/>}/>
              </SiteLink>
            </li>
          </ul>
        </Scroller>
      </Section>
    }
  </>
)


// GraphQL
// -------

export const query = graphql`
  query MinistryQuerySingle($slug: String!) {
    datoCmsMinistry(slug: {eq: $slug}) {
      ...MinistryFragment
    }
    allDatoCmsMinistryBranch(filter: {ministry: {slug: {eq: $slug}}}, sort: {fields: position, order: ASC}) {
      edges {
        node {
          ...MinistryBranchFragment
        }
      }
    }
  }
`

// meta___updatedAt
// allDatoCmsMinistryBranch(sort: {fields: position, order: ASC}, filter: {ministry: {slug: {eq: $slug}}}) {
